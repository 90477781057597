
const BannerCajaGrande = (props) => {
    const {
        bannerId,
        isPreview,
        lazyload = 0,
        autorefresh = 0,
    } = props;

    return isPreview ? (
        <div className={`banner-preview`}>
            <p className={`banner-reserved-space`}>Banner Caja Grande {bannerId}</p>
        </div>
    ) : (
        <div className={`parent-banner ad-slot-background`}>
            <div
                className={`ad-slot ad-slot-caja_grande`}
                id={bannerId}
                data-adtype="caja_grande"
                data-lazyload={lazyload}
                data-autorefresh={autorefresh}
            />
        </div>
    )
}

export default BannerCajaGrande;